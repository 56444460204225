import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Header } from '../common/Header/Header'
import { BarMenu } from '../common/BarMenu/BarMenu';
import initData from '../Home/initData';
//CSS
import './cart.css';
import Payments from './Payments';

export const ShoppingCart = () => {
   const navigate = useNavigate();
   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [handlerEvent, setHandlerEvent] = useState(0);
   const [modalShow, setModalShow] = React.useState(false);
   const [shoppingcart, setShopingcart] = useState([]);
   const [payments, setPayments] = useState([]);
   const [selectedPayment, setSelectedPayment] = useState({})


   //Totals states 
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0);
   const [taxesArrayAll, setTaxesArrayAll] = useState([]);

   //=====================CLEAR SHOPPINGCART=============================

   const clearShoppingcart = () =>{
      localStorage.setItem('shoppingCart', JSON.stringify([]))
      setShopingcart([])
      calculateInvoiceTotals([])
      navigate(`/home/${business_code}/${branchoffice_code}/${machine_code_table}`);
   }

   //==========================CALCULATE===========================

   //decimal reduction
   const decimalReduction = (value) => {

      let string = value.toString()
      let arrayString = string.split(".");
      let newDecimalString = []
      let sample = ''
      if (arrayString[1] != undefined) {
         newDecimalString = arrayString[1].slice(0, 2);
         sample = arrayString[0] + "." + newDecimalString
         console.log("Hice la reduccion");
      } else {
         console.log("Array: " + arrayString);
         console.log("Decimal: " + newDecimalString);
         console.log("Nuevo valor: " + sample);
         sample = string;
      }

      let endValue = parseFloat(sample)
      return endValue
   }


   //Function to calculate the invoice totals
   const calculateInvoiceTotals = (products) => {

      //For every new calculate we set the totals
      let taxesArray = [];
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //if we dont we products we put the totals in 0 for default
      if (products.length === 0) {
         //We calculate the totals of the invoice
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setTaxesArrayAll(taxesArray)
      } else {
         //For every product we calculate
         for (let i = 0; i < products.length; i++) {
            let product_info = products[i]
            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product
            product_total_discount = (product_info.unit_discount * product_info.amount);
            //We set the total of the product
            total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));

            //Calculate tax by group--------------------------------
            let taxesGroup = product_info.taxes;
            taxesGroup.forEach(tax => {

               //calculate tax  value and add it to array


               let valueTax = decimalReduction((product_info.amount * ((product_info.price - product_info.unit_discount) * tax.taxtValue)));
               tax.total = valueTax;
               //Identifier tax of the group position
               var index = taxesArray.findIndex(function (info, i) {
                  return info.tax_id === tax.tax_id
               });

               if (index < 0) {
                  let taxInfoNow = {
                     "dTasaITBMSValue": tax.dTasaITBMSValue,
                     "percentage": tax.percentage,
                     "tax": tax.tax,
                     "tax_id": tax.tax_id,
                     "taxtValue": tax.taxtValue,
                     "total": valueTax
                  }
                  taxesArray.push(taxInfoNow);
               } else {
                  taxesArray[index].total = (taxesArray[index].total + valueTax)
               }

               total_product_itbms = (total_product_itbms + valueTax)

            });
            //-------------------------------------------------------


            //We set the itbms of the product
            //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

            //We calculate the totals of the invoice
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = decimalReduction(invoiceSubtotal + total_product_price);
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = decimalReduction(invoiceTotal + total_product_price + total_product_itbms);

            console.log("invoiceTotalDiscount:" + invoiceTotalDiscount);
            console.log("invoiceSubtotal:" + invoiceSubtotal);
            console.log("invoiceTotalItbms:" + invoiceTotalItbms);
            console.log("invoiceTotal:" + invoiceTotal);
         }
         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);
         setTaxesArrayAll(taxesArray);
      }

      setHandlerEvent(1)

   }

   //==========================EDIT AMOUNTS PRODUCTS ============================

   //Function to incremet the product amount on the shopping cart
   const decrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingcart[product_index];
      //We affect the product data
      product.amount = product.amount - 1;
      product.cantidadItem = product.amount
      product.descuento = product.descuento - product.unit_discount;
      product.discount = product.discount - product.unit_discount;

      //We check if the product amount is lower than 0
      if (product.amount <= 0) {
         //if is lower or equal to 0 we delete the product of the shopping cart list
         shoppingcart.splice(product_index, 1);
         setShopingcart([...shoppingcart])
      }

      localStorage.setItem('shoppingCart', JSON.stringify(shoppingcart))
      //recharge shoppingcart
      calculateInvoiceTotals(shoppingcart)
   };

   //Function to incremet the product amount on the shopping cart
   const incrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingcart[product_index];
      //We affect the product data
      product.amount = product.amount + 1;
      product.cantidadItem = product.amount;
      product.descuento = product.descuento + product.unit_discount;
      product.discount = product.discount + product.unit_discount;

      localStorage.setItem('shoppingCart', JSON.stringify(shoppingcart))
      //recharge shoppingcart
      calculateInvoiceTotals(shoppingcart)
   };


   //----------------------Data general-----------------------\

   //consolidates data into corresponding statements
   const dataconsolidation = (generalData) => {
      if(generalData == 'error'){navigate(`/branch/${business_code}`)}
 let data = generalData
      if (localStorage.getItem('shoppingCart') == null || localStorage.getItem('shoppingCart') == undefined || localStorage.getItem('shoppingCart') == "") {
         setShopingcart([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCart'))
         setShopingcart(datashopingcart)
         calculateInvoiceTotals(datashopingcart)
      }
     

      //==========Payments==============
      setPayments(data.paymentMethods);

      console.log(data.paymentMethods);
   }

   const handlerInit = async () => {
      try {
         let response = await initData()
         dataconsolidation(response)
      } catch (err) {
         navigate(`/branch/${business_code}`)
         console.log(err);
      }
   }

   useEffect(() => {
      handlerInit()
   }, [])

   useEffect(() => {
      setHandlerEvent(0)
   }, [handlerEvent])
   return (

      <>
         <section className='ecomerce-container-0'>
            <div className='ecomerce-container-1'>
               <Header></Header>
               <div className='ecomerce-container-info mx-2'>
                  <h4 className='ecomerce-container-info-title fw-bold mt-2 text-center'>
                     <i class="uil uil-receipt"></i>
                     Orden
                  </h4>
               </div>



               <div className='ecomerce-container-section-0 border border-5 border-light bg-light pb-5'>
                  {shoppingcart.map((product, index) => (
                     <div class="ecommerce-menu-card d-flex align-items-center bg-white rounded-3 mt-1 shadow-sm mx-1 p-1">
                        <div class="flex-shrink-0">
                           <img src={product.img}
                              className='img-ecommerce-menu-card rounded-3 border' alt='...' />
                        </div>
                        <div class="flex-grow-1 ms-3">
                           <div className='mt-2 me-2'>
                              <span className='mt-1 title-ecommerce-menu-card '> {product.name} </span>
                              {/* <span className='mt-1 d-block description-ecommerce-menu-card '>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                             Architecto quaerat culpa fuga alias odio, nesciunt voluptate ipsum nulla.
                              Accusamus sit a eum laborum nobis earum expedita blanditiis minus nihil voluptate.
                          
                         </span> */}
                              <div className='d-block'>
                                 <details>
                                    <summary>
                                       <small className='badge rounded-pill text-secondary bg-secondary-subtle '>
                                          <i class="uil uil-info-circle"></i>
                                          Detalles
                                       </small>
                                    </summary>
                                    <div className='mt-1'>

                                       <div class="d-flex flex-column flex-md-row gap-1 align-items-center justify-content-center">
                                          {/* Item 1 */}

                                          {product.segments.map((segment) => (
                                             <div class="list-group w-100">
                                                <a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                                                   <div class="d-flex gap-2 w-100 justify-content-between">
                                                      <div>
                                                         <h6 class=" opacity-50 mb-0">{segment.segment_name}</h6>
                                                         <h6 class="mb-0">{segment.item.name}</h6>
                                                         {/* <p class="mb-0 opacity-75">Some placeholder content in a paragraph.</p> */}
                                                      </div>
                                                      <small class="opacity-50 text-nowrap">+ ${segment.price}</small>
                                                   </div>
                                                </a>

                                             </div>))}

                                       </div>
                                    </div>
                                 </details>
                              </div>
                              <div className='d-flex justify-content-between align-items-center'>
                                 <span className='mt-1 d-block value-ecommerce-menu-card'> $ {product.price} </span>
                                 <div className='d-flex justify-content-around gap-1 align-items-center'>
                                    <div onClick={() => decrementProductAmount(index)}><span><i class="uil uil-minus-circle item-count-product"></i></span></div>
                                    <span className='value-ecommerce-menu-card text-dark'>{product.amount}</span>
                                    <div onClick={() => incrementProductAmount(index)}><span><i class="uil uil-plus-circle item-count-product"></i></span></div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  ))}
               </div>

               <div className="d-flex justify-content-between ">
                  <div className="d-flex m-1 text-center">
                     <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce  bg-light">
                        Descuento: $
                        <span translate='no' className='fw-bold'>{invoiceTotalDiscount}</span>
                     </span>
                  </div>
                  <div className="d-flex m-1 text-center">
                     <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce bg-light"> Impuesto: $
                        <span translate='no' className='fw-bold text-center'>{invoiceTotalItbms}</span>
                     </span>
                  </div>
                  <div className="d-flex m-1 text-center">
                     <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce bg-light"> Subtotal: $
                        <span translate='no' className='fw-bold text-center'>{invoiceSubtotal}</span>
                     </span>
                  </div>
               </div>

               <div className='p-1'>
                  <div className='d-flex justify-content-between align-items-center total-shopping'>
                     <span className='item-value-product'>
                        Total
                     </span>
                     <span className='item-value-product'>
                        $ {invoiceTotal}
                     </span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center '>
                     <button className='btn btn-cancel w-100 p-2 m-1' onClick={() => clearShoppingcart()}  >
                        Cancelar
                     </button>
                     <button className='btn btn-add-product w-100 p-2 m-1' onClick={() => setModalShow(true)}  >
                        Pagar  $ {invoiceTotal}
                     </button>

                  </div>

               </div>

               <BarMenu shoppingcart={shoppingcart} ></BarMenu>
            </div>
         </section>

         <Payments modalShow={modalShow}
            setModalShow={setModalShow}
            payments={payments} 
            invoiceTotal={invoiceTotal}
            selectedPayment={selectedPayment} setSelectedPayment={setSelectedPayment}/>
      </>

   )
}
