import React from 'react'
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
//CSS
import './orders.css';
import InfoOrdersModal from './InfoOrdersModal';

export const Orders = () => {
    const [modalShow, setModalShow] = React.useState(false);

   return (
      <>


         <section className='ecomerce-container-0'>
            <div className='ecomerce-container-1'>
               <Header></Header>



               <div className='ecomerce-container-section-0 border border-1 border-light pb-5'>


                  <div className='rounded-2 mx-2 sticky-top'>
                     <div className='bg-header-orders p-1 text-center'>
                        <span>Pedidos </span>
                     </div>
                  </div>

                  <div class="ecommerce-orders-card d-flex align-items-center bg-white rounded-3 shadow-sm mt-1 mx-1 p-1">
                     <div className="mark flex-grow-1 bg-success-subtle py-5 rounded-3" >
                     </div>
                     <div className="flex-grow-1 ms-2">
                        <div className='mt-2 me-2'>
                           <span className='mt-1 title-ecommerce-orders-card '>
                              <i class="uil uil-receipt"></i> Order: 4532
                           </span>
                           <span className='mt-1 d-block description-ecommerce-orders-card '>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                              Architecto quaerat culpa fuga alias odio, nesciunt voluptate ipsum nulla.
                              Accusamus sit a eum laborum nobis earum expedita blanditiis minus nihil voluptate.

                           </span>
                           <div className='d-flex justify-content-between mt-1'>
                              <span className='mt-1 d-block value-ecommerce-orders-card'>Total  $ 89.00 </span>
                              <button type="button" class="btn btn-primary btn-sm" onClick={() => setModalShow(true)} >Ver más</button>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
               <BarMenu></BarMenu>
            </div>
         </section>

         <InfoOrdersModal show={modalShow}
            onHide={() => setModalShow(false)} />
      </>
   )
}
