import React from 'react'
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
//css
import './profile.css'
import LoginStand from './LoginStand'
import LoginUser from './LoginUser'

//img
import ImgProfile from '../../assets/img/logos/profile-login.png';
export const Porfile = () => {
  const [modalUser, setModalUser] = React.useState(false);
  const [modalStand, setModalStand] = React.useState(false);
  return (
    <>
      <section className='ecomerce-container-0'>
        <div className='ecomerce-container-1'>
          <Header></Header>

          {/* Si no se han logeado sale este  */}
          {/* <div className='ecomerce-container-section-0'>
            <div className='d-flex justify-content-center'>
              <img className='header-profile-img-logo'
                src={ImgProfile} alt="" />
            </div>

            <div class="my-5 mx-4">
              <div class="d-grid gap-2">
                <span className='fw-bold  text-center  h5' >Ingresa a tu cuenta </span>
                <button class="btn btn-success fw-bold  " type="button" onClick={() => setModalUser(true)}>
                  <i class="uil uil-user-circle me-2"></i>
                  Iniciar Sesión
                </button>
                <span className='fw-bold h5 text-center mt-1'>O </span>
                <button class="btn btn-primary fw-bold" type="button" onClick={() => setModalStand(true)}>
                  <i class="uil uil-web-grid-alt me-2"></i>
                  Ingresar como Stand
                </button>
              </div>
            </div>
          </div> */}
          {/* Si  se loguearon entonces saldra esto   */}
          <div className='ecomerce-container-section-0  px-2'>
            <div className='bg-header-profile p-1'>
              <small>Hello </small>
              <h3>Alberto Orozco  </h3>
            </div>
            <div class="d-flex flex-column py-2 ">
              <h4 className='fw-bold'>Datos de usuario</h4>
              <div class="list-group">
                <a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">

                  <div className='flex-shrink-0 '>
                    {/*<i class="uil uil-envelope bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                    <i class="uil uil-bed bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                  </div>
                  <div class="d-flex gap-2 w-100 justify-content-between">
                    <div>
                      <h6 class="mb-0">Habitacion:</h6>
                      <p class="mb-0 opacity-75">777</p>
                    </div>

                  </div>
                </a>
                <a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-3 border" aria-current="true">
                  <div className='flex-shrink-0'>
                  {/*<i class="uil uil-phone bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>*/}

                  <i class="uil uil-user-circle bg-primary-subtle rounded-circle py-1 text-primary px-2"></i>
                  </div>
                  <div class="d-flex gap-2 w-100 justify-content-between">
                    <div>
                      <h6 class="mb-0">Huésped:</h6>
                      <p class="mb-0 opacity-75">Alberto Orozco</p>
                    </div>

                  </div>
                </a>
                {/* 
                <div className=' d-flex  justify-content-center'>
                  <span className='fw-bold text-danger'>
                    <i class="uil uil-signout"></i>
                    Cerrar Sesión
                  </span>

                </div>*/}

              </div>
            </div>
          </div>
          <BarMenu></BarMenu>
        </div>
      </section>


      <LoginStand show={modalStand} onHide={() => setModalStand(false)} />


      <LoginUser show={modalUser} onHide={() => setModalUser(false)} />


    </>
  )
}
