import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';


export const ModalProduct = ({ modalShow, setModalShow, selectedGroup, segmentsGroup }) => {
   let title_categori = 'Ensalada552'
   let title_categori1 = 'Sopa'
   const [segments, setSegments] = useState([])
   const [amount, setAmount] = useState(1)
   const [handlerevent, setHandlerEvent] = useState(0)
   const [modalViewImg, setModalViewImg] = useState(false)
   const navigate = useNavigate()
   const { business_code, branchoffice_code, machine_code_table } = useParams();


   // format json of product to add shopppingcart
   const formatJsonToAddShoppingCart = () => {

      let formatProductAdd = selectedGroup

      if (formatProductAdd.img == null || formatProductAdd.img == "" || formatProductAdd.img == undefined) {
         formatProductAdd.img = localStorage.getItem('logo')
      }
      //For default we set that the amount of the selected product depending the number 
      formatProductAdd.amount = amount;
      formatProductAdd.cantidadItem = amount;
      formatProductAdd.delivery_type = "comer aqui";
      formatProductAdd.note = '';
      formatProductAdd.unit_discount = 0;
      formatProductAdd.product_discount = 0;
      formatProductAdd.base_price = formatProductAdd.price;
      formatProductAdd.descuento = formatProductAdd.product_discount;
      formatProductAdd.discount = formatProductAdd.product_discount;
      //For default we initialice the segments empty of the selected product 
      formatProductAdd.segments = [];
      formatProductAdd.DetalleSegmentos = [];
      formatProductAdd.segments_string = ""

      //find segments checked
      let temporal_segment_string = ""
      segmentsGroup.forEach((segment) => {
         segment.items.forEach((item) => {
            let idCheckedItem = document.getElementById("listGroupRadioGrid" + segment.segment_id + item.item_id)

            if (idCheckedItem.checked == true) {
               item.amount = amount
               temporal_segment_string = formatProductAdd.segments_string + " " + segment.name + ": " + item.name
               formatProductAdd.segments_string = formatProductAdd.segments_string + " " + segment.name + ": " + item.name
               formatProductAdd.segments.push({ ...item, segment_name: segment.name, cantidadItem: amount, item: item })
               formatProductAdd.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: amount, item: item })
               formatProductAdd.price = parseFloat(formatProductAdd.price + item.price).toFixed(2)
            }
         })

      });

      addProductShopingcart(formatProductAdd)

   }


   const addProductShopingcart = (product) =>{
      let shoppingCart = localStorage.getItem('shoppingCart');
      let dataProduct = []
      if (shoppingCart == null || shoppingCart == undefined || shoppingCart == "") {
         dataProduct = []
      }else{
         dataProduct = JSON.parse(shoppingCart)
      }

      dataProduct.push(product)
      localStorage.setItem('shoppingCart', JSON.stringify(dataProduct))

      setModalShow(false)
      navigate(`/cart/${business_code}/${branchoffice_code}/${machine_code_table}`)
   }

   // Función para incrementar el contador
   const increment = () => {
      setAmount(amount + 1);
      setHandlerEvent(1)
   };

   // Función para decrementar el contador
   const decrement = () => {
      if (amount <= 1) {
         setAmount(1);
      } else {
         setAmount(amount - 1);
      }
      setHandlerEvent(1)
   };


   useEffect(() => {
      setHandlerEvent(0)
   }, [handlerevent])
   return (
      <Modal
         show={modalShow}
         onHide={() => setModalShow(false)}
         size="lg"
         centered>
         <Modal.Body className='p-0'>
            <section className='ecomerce-container-product-0'>
               <div className='ecomerce-container-1'>
                  <div className='position-relative'>

                     <div className='position-absolute top-0 start-0 p-2 w-100 d-flex justify-content-between '>
                        <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={() => setModalShow(false)}>
                           <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                        </button>
                        <button className='btn btn-header-ecommerce-product   btn-sm rounded-3' onClick={() => setModalViewImg(true)}>
                           <i class="uil uil-search-plus icon-header-ecommerce"></i>
                        </button>
                     </div>
                     <img className='img-ecommerce-menu-product'
                        src={selectedGroup.img} alt="..." />
                  </div>
                  <div className='ecomerce-container-info mx-2'>
                     <h4 className='ecomerce-container-info-title fw-bold mt-2'>{selectedGroup.name} </h4>
                     <p className='ecomerce-container-info-description '>{selectedGroup.description}</p>

                  </div>

                  <div className='ecomerce-container-section-0 border border-5 border-light bg-light pb-5'>
                     {/* Content Tabs  */}
                     {segmentsGroup.map((segment) => (
                        <div className='m-1'>

                           <h6 className='fw-bold mt-2'>{segment.name}</h6>
                           <div className="d-flex gap-1 overflow-auto ps-1">
                              {segment.items.map((item, index) => (
                                 <><div className='list-pre-orders-inputs '>
                                    <input
                                       className="list-group-item-check "
                                       type={segment.type == 'MULT' ? "checkbox" : "radio"}
                                       id={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                                       name={"listGroupRadioGrid" + segment.segment_id}
                                       defaultChecked={segment.segment_item_id_default == null ? index == 0 ? true : false : segment.segment_item_id_default == item.item_id ? true : false}
                                    />
                                    <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-0 categories-menu shadow-sm"
                                       htmlFor={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                                    >
                                       <div className="d-flex flex-block   gap-1 py-1 rounded" >
                                          <img src={item.img}
                                             alt="Product" className="rounded  flex-shrink-0  img-categories-menu" />
                                       </div>
                                       <div className="d-block px-1">
                                          <span className={`text-categories-menu text-center ${item.name.length > 10 ? 'text-categories-menu-slider ' : 'text-categories-menu '}`}>
                                             {item.name}
                                          </span>
                                          <span className='d-block text-center'> $ {parseFloat(item.price).toFixed(2)}</span>
                                       </div>
                                    </label>
                                 </div></>
                              ))}

                           </div>
                        </div>
                     ))}
                  </div>



                  <div className='p-1'>
                     <div className='d-flex justify-content-between align-items-center'>
                        <span className='item-value-product m-2'>
                           $ {parseFloat(selectedGroup.price).toFixed(2)}
                        </span>
                        <div className='d-flex justify-content-around gap-1 align-items-center m-2'>
                           <div onClick={() => decrement()}><span><i class="uil uil-minus-circle item-count-product"></i></span></div>
                           <span className=' item-count-product'>{amount}</span>
                           <div onClick={() => increment()}><span><i class="uil uil-plus-circle item-count-product"></i></span></div>
                        </div>
                     </div>
                     <button className='btn btn-add-product w-100' onClick={() => formatJsonToAddShoppingCart()} > Agregar al carrito</button>
                  </div>

               </div>
            </section>




            <Modal
              show={modalViewImg}
              onHide={() => setModalViewImg(false)}
              size="s"
              centered>
               <Modal.Body>
                  <img src={selectedGroup.img} className='img-ecommerce-menu-product-preview'></img>
               </Modal.Body>
            </Modal>

         </Modal.Body>
         {/* <Button onClick={props.onHide}>Close</Button>     */}
      </Modal>


   )
}
