import React, {Profiler, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
//Components
// import LandingPage from './components/_index';

//API 
//context 
import UserContext from "./context/UserContext";

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/js/bootstrap.esm'
import './App.css';
import './index.css';
import KioskMainEcommers from "./layouts/Kiosk_ecommers_stant/KioskMainEcommers.jsx";
import BranchofficeSelect from "./components/Branch/BranchofficeSelect.jsx";
//Icons
import 'bootstrap-icons/font/bootstrap-icons.css'
import { Home } from "./components/Home/Home.jsx";
import { Branch } from "./components/Branch/Branch.jsx";
import { Menu } from "./components/Menu/Menu.jsx";
import { Orders } from "./components/Orders/Orders.jsx";
import { Porfile } from "./components/Profile/Porfile.jsx";
import { ShoppingCart } from "./components/ShoppingCart/ShoppingCart.jsx";

import NoMatch from "./components/common/layout/NoMatch.jsx";


function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  });


  return (
    <>
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            {/* Index Main Menu  */}
            <Route  path="/" element={<Home />}/>
            {/* Ecommerce */}
            <Route  path="/home/:business_code/:branchoffice_code/:machine_code_table" element={<Home />}/>
            <Route  path="/branch/:business_code" element={<BranchofficeSelect />} />
            <Route  path="/menu/:business_code/:branchoffice_code/:machine_code_table" element={<Menu />}/>
            <Route  path="/orders/:business_code/:branchoffice_code/:machine_code_table" element={<Orders />} />
            <Route  path="/profile/:business_code/:branchoffice_code/:machine_code_table" element={<Porfile />} />
            <Route  path="/cart/:business_code/:branchoffice_code/:machine_code_table" element={<ShoppingCart />} />

            <Route path="/kiosk/ecommers/stant/:business_code/:branchoffice_code/:machine_code_table" element={<KioskMainEcommers />} />
            <Route path="/kiosk/ecommers/branchshoffices/:business_code" element={<BranchofficeSelect />}></Route>

            {/* No Match link */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
