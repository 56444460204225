import React from 'react'
import Modal from 'react-bootstrap/Modal';
const Lenguage = (props) => {
   return (
      <>
         <Modal
            {...props}
            size="lg"

            centered
         >

            <Modal.Body className='p-0'>
               <section className='ecomerce-container-product-0'>
                  <div className='ecomerce-container-1'>
                     <div className='position-relative'>

                        <div className=' p-2 w-100 d-flex justify-content-between align-items-center  '>
                           <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={props.onHide}>
                              <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                           </button>
                           <h4 className='fw-bold text-center'>
                              <i className="uil uil-english-to-chinese"></i> Idiomas
                           </h4>
                           {/* <button className='btn btn-header-ecommerce-product   btn-sm rounded-3'>
                              <i class="uil uil-info icon-header-ecommerce"></i>
                           </button> */}
                           <span className='px-3'></span>
                        </div>

                     </div>


                     <div className='ecomerce-container-info mx-2'>
                        <h4 className='ecomerce-container-info-title fw-bold mt-2'>Seleccione un idoma</h4>

                     </div>

                     <div className='ecomerce-container-section-0 border border-1 border-primary bg-light pb-5'>
                        <div className=''>
                           <input type="radio" class="btn-check w-100 px-5" name="options-base" id="option5" autocomplete="off" checked />
                           <label class="btn" for="option5">Ingles </label>
                        </div>


                        <input type="radio" class="btn-check px-5" name="options-base" id="option6" autocomplete="off" />
                        <label class="btn" for="option6">Chino </label>

                     </div>
                     <div className='py-2'></div>
                  </div>
               </section>
            </Modal.Body>


         </Modal>

      </>
   )
}

export default Lenguage