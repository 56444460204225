import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import './header.css'
import Lenguage from '../Lenguage/Lenguage';
export const Header = () => {

   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [businessCode, setbusinessCode] = useState(business_code);
   const [modalShow, setModalShow] = React.useState(false);
   let logo = localStorage.getItem('logo')
   let brachoffice_name = ''

   if (localStorage.getItem("branchoffice") != "" && localStorage.getItem("branchoffice") != undefined ) {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      brachoffice_name =branchoffice.name
   }
   return (
      <>
         <header className='text-center fw-bold d-flex justify-content-between  align-items-center shadow-sm'>

            <NavLink
               to={`/branch/${businessCode}`}
            // className={({ isActive }) => isActive ? 'btn active-link btn-menu jello-horizontal m-0' : 'btn no-active-link m-0 p-0'}
            >
               <button className='btn btn-header-ecommerce   btn-sm rounded-3'>
                  <i className="uil uil-store icon-header-ecommerce"></i>
                  {/* <span className=''> Sucursales  </span> */}
               </button>
            </NavLink>


            <div>
               <img src={logo}
                  alt="Logo Bussiness" className='img-ecommerce-header rounded-3' />
               {/* <i className={'uil uil-estate'}></i>
                     <small className='d-incline '>Home</small> */}
            </div>
            <button className='btn btn-header-ecommerce  btn-sm rounded-3' onClick={() => setModalShow(true)}>
               <i className="uil uil-english-to-chinese icon-header-ecommerce"></i>
               {/* <span > Idioma </span> */}
            </button>
         </header>

         <div className='d-flex justify-content-center  bg-light px-1 shadow-sm'>
            <small className='fw-bold  text-store-header text-dark-emphasis' >  <i className="uil uil-store" ></i>Sucursal: {brachoffice_name} </small>
         </div>

         <Lenguage
            show={modalShow}
            onHide={() => setModalShow(false)}
         />
      </>


   )
}
