import { useState, useEffect } from 'react'

export function useFetch(url) {

   const [data, setData] = useState([]);
   const [loading, setLoading] = useState();

   useEffect(() => {
      fetch(url)
         .then((response) => response.json())
         .then((data) => setData(data));
   }, []);

   return { data }

}