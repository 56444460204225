import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import './BarMenu.css'
export const BarMenu = ({shoppingcart}) => {
  const navigate = useNavigate();
  const { business_code, branchoffice_code, machine_code_table } = useParams();
 
  let businessCode = business_code;
  let branchofficeCode = branchoffice_code;
  let machineCodeTable = machine_code_table;


  let countProduct = 0
  if (shoppingcart != undefined) {

    shoppingcart.forEach((product) => {
      countProduct = (countProduct+ parseInt(product.amount))
    });
    
  }
  

  return (
    <>
      <div className=''>
        <div className='bar-menu d-flex justify-content-around '>
          <NavLink
            to={`/home/${businessCode}/${branchofficeCode}/${machineCodeTable}`}
            className={({ isActive }) => isActive ? 'btn  active-link  btn-menu jello-horizontal' : 'btn no-active-link '}
          >
            <i className={'uil uil-estate'}></i>
            <small className='d-block '>Inicio</small>
          </NavLink>

          <NavLink
            to={`/orders/${businessCode}/${branchofficeCode}/${machineCodeTable}`}
            className={({ isActive }) => isActive ? 'btn active-link btn-menu jello-horizontal' : 'btn  no-active-link'}
          >
            <i className="uil uil-receipt"></i>
            <small className='d-block '>Pedidos</small>
          </NavLink>

          <NavLink
            to={`/menu/${businessCode}/${branchofficeCode}/${machineCodeTable}`}
            className={({ isActive }) => isActive ? 'btn active-link btn-menu jello-horizontal' : 'btn  no-active-link'}
          >
            <i className="uil uil-square-shape"></i>
            <small className='d-block '>Menú</small>
          </NavLink>



          <NavLink
            to={`/cart/${businessCode}/${branchofficeCode}/${machineCodeTable}`}
            className={({ isActive }) => isActive ? 'btn active-link btn-menu jello-horizontal position-relative' : 'btn no-active-link position-relative'}
          >
            <i className="uil  uil-shopping-cart"></i>
            <small className='d-block '>Carrito</small>
            {
              countProduct > 0
                ? <>
                  <span class="position-absolute  badge rounded-pill text-bg-warning p-1">
                    +{countProduct}
                  </span>
                </>
                : <>
                </>
            }

          </NavLink>

          <NavLink
            to={`/profile/${businessCode}/${branchofficeCode}/${machineCodeTable}`}
            className={({ isActive }) => isActive ? 'btn active-link btn-menu jello-horizontal' : 'btn no-active-link '}
          >
            <i className="uil uil-user"></i>
            <small className='d-block '>Perfil</small>
          </NavLink>
        </div>
      </div>

    </>
  )
}
