import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
const LoginStand = (props) => {
   return (
      <>
         <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered         >

            <Modal.Body>
               <div className='position-absolute '>
                  <div className='fw-bold badge rounded-2 text-bg-primary'>
                     <i class="uil uil-shop"></i>  Stan
                  </div>
               </div>
               <h2 className={'kiosk-delivery_title text-center text-primary '}>
                  Inicio de Sesión
               </h2>
               <div className='d-flex align-items-center justify-content-center'>
                  <div className=" text-dark  rounded-4 position-relative text-wrap bg-white   p-2">
                     <div className='d-flex'>
                        <Form>
                           <Form.Group className="mb-3 kiosk-text-card">
                              <span className="fw-bold">Selecciona un Stan</span>
                              <Select
                                 className='border-2 w-100'
                              />
                           </Form.Group>
                           <div className="mb-3 kiosk-text-card">
                              <span className="fw-bold">Contraseña</span>
                              <input type="password" className="form-control border rounded-1 w-100" placeholder="**********"
                              />
                           </div>
                        </Form>
                     </div>
                     <button className={`btn btn-primary rounded btn-sm kiosk-subtitle-md position-relative px-4 w-100`}
                     >
                        Ingresar
                     </button>
                  </div>
               </div>
            </Modal.Body>
            {/* <Modal.Footer>
               <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
         </Modal>
      </>
   )
}

export default LoginStand